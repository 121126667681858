import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import '../../App.css';
import Mergedindex from '../../Website/Index/Mergedindex';

function Main() {
  return (

    <Routes>
      <Route path='/' element={<Mergedindex />} />
      
    </Routes>

  );
}

export default Main;
